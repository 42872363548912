import React from "react";
import Close from "../../../images/close.svg";
import { onYouTubeIframeAPIReady } from "../../../utils/analytics/index";
interface VideoModalProps {
  src: string;
  setActiveVideo: any;
}

const VideoModal: React.FC<
  React.PropsWithChildren<VideoModalProps>
> = props => {
  const { src, setActiveVideo } = props;
  setTimeout(() => onYouTubeIframeAPIReady(src), 500);
  return (
    <>
      <div
        onClick={() => setActiveVideo("")}
        className="fixed top-0 h-full w-full bg-purple-2-dark bg-opacity-80 z-50"
      ></div>
      <div className="fixed z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex justify-center w-[90%] h-[350px] md:h-full max-h-[700px] max-w-[1200px]">
        <div id="yt-player" className="rounded-2xl overflow-hidden w-full"></div>
        <button
          onClick={() => setActiveVideo("")}
          className="absolute bg-indigo-1 h-10 w-10 -top-3 -right-3 rounded-full flex items-center justify-center focus:outline-none"
        >
          <Close />
        </button>
      </div>
    </>
  );
};

export default React.memo(VideoModal);