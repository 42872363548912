import React from "react";
import Header, { HeaderVariant } from "../../Header";
import Video from "../../../images/video.svg";

interface VideoFeatureCardProps {
  publication: any;
  setActive: any;
  id?: string;
}

const VideoFeatureCard: React.FC<VideoFeatureCardProps> = props => {
  const { publication, setActive, id } = props;
  const { title, thumbnails = {}, publishedAt } = publication;
  const { high } = thumbnails;

  return (
    <div
      onClick={() => setActive(id)}
      className="sm:mx-4 cursor-pointer col-span-8 grid lg:grid-cols-2 lg:mb-12 group"
    >
      <div className="col-span-1 overflow-hidden relative rounded-2xl max-h-[350px] lg:mr-3">
        <div className="absolute rounded-2xl z-20 opacity-0 w-full h-full bg-gradient-to-br from-blue-1 to-purple-1 transition duration-200 ease-in group-hover:opacity-50"></div>
        <img
          className="w-full scale-105 group-hover:scale-100 transition duration-200 ease-in"
          src={`https://img.youtube.com/vi/${id}/maxresdefault.jpg`}
          alt="preview"
        />
        <Video className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
      </div>
      <div className="col-span-1 flex flex-col justify-center pt-9 lg:px-8">
        <Header
          variant={HeaderVariant.h3}
          className="text-3xl md:text-4xl mb-3 group-hover:text-indigo-1"
        >
          {title}
        </Header>
        <p className="lowercase pt-4 text-base">{publishedAt}</p>
      </div>
    </div>
  );
};

export default VideoFeatureCard;
