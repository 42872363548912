import { graphql, Link } from "gatsby";
import React, { useEffect, useMemo, useState } from "react";

import Layout from "../../containers/Layout";
import { trackView } from "../../utils/analytics/index";
import { Category, Name } from "../../utils/analytics/constants";
import Section from "../../containers/Section";
import VideoModal from "../../components/Blog/VideoModal";
import EmbeddedCta from "../../components/Blog/EmbeddedCta";
import ResultsNavigation from "../../components/Blog/ResultsNavigation";
import { NavigationBarVariant } from "../../components/NavigationBar/models";
import VideoList from "../../components/Blog/VideoList";
import GradientSection from "../../containers/GradientSection";
import cn from "classnames";
import NavigationBar from "../../components/NavigationBar";
import EmbeddedCtaPop from "../../components/Blog/EmbeddedCtaPop";
import LeftArrow from "../../images/left-arrow.svg";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import { BreadcrumbPageType, formatBreadcrumb } from "../../utils/breadcrumb";

const VideoCategory = props => {
  const [activeVideo, setActiveVideo] = React.useState<string>("");
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [isTop, setIsTop] = useState<boolean>(true);
  const { pageContext } = props;

  const categories = {
    firstlook: {
      videos: props?.data?.firstlook.nodes,
      textColor: "text-gray-4-dark",
      bgColor: "gray-7"
    },
    growth101: {
      videos: props?.data?.growth.nodes,
      textColor: "text-red-2-dark",
      bgColor: "red-3"
    },
    insights: {
      videos: props?.data?.insights.nodes,
      textColor: "text-purple-2-dark",
      bgColor: "purple-3"
    },
    customerstories: {
      videos: props?.data?.customerstories.nodes,
      textColor: "text-green-2-dark",
      bgColor: "green-3"
    }
  };

  const currentSection =
    categories[pageContext.title.split(" ").join("").toLowerCase()];

  const handleScroll = () => {
    if (window && window?.pageYOffset > 0) {
      setIsTop(false);
    } else {
      setIsTop(true);
    }
  };

  useEffect(() => {
    trackView(Name.VideoCategory, {
      blog_post_category: pageContext.name,
      category: Category.PageView
    });

    window?.addEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (activeVideo !== "") {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  }, [activeVideo]);

  const updatedCrumbs = useMemo(
    () => formatBreadcrumb(pageContext, BreadcrumbPageType.VideoCategory),
    []
  );

  return (
    // Hide nav from layout but retain other stuff
    <Layout inlineSwitcher hideNav>
      {/* Pull in blog nav variation */}
      <NavigationBar variant={NavigationBarVariant.blog} />
      <div className="hidden md:block">
        <ResultsNavigation isTop={isTop} videoPage={true} />
      </div>
      <GradientSection
        className={cn("bg-gradient-to-b", "from-" + currentSection?.bgColor)}
      >
        <div className="pt-22 md:pt-56">
          <div className="mb-8">
            <CustomBreadcrumb crumbs={updatedCrumbs} />
          </div>
          <div className="md:hidden pb-8">
            {/* Mobile only */}
            <div className={cn("flex justify-between pt-3 items-center")}>
              <Link
                className="text-black py-4 flex gap-4 items-center"
                to={"/video/"}
              >
                <LeftArrow className="mr-3" />
                video
              </Link>
              <EmbeddedCtaPop />
            </div>
          </div>
          <VideoList
            title={pageContext.title}
            videos={currentSection?.videos}
            color={currentSection.textColor}
            setActiveVideo={setActiveVideo}
            categoryPage
            pagination
          />
        </div>
      </GradientSection>
      <Section mobileWide className="px-0 sm:px-4 sm:py-12">
        <EmbeddedCta
          fullWidth
          buttonText="Sign Up"
          title="Insights to help grow profit"
          textFieldPlaceholder="Email address"
          successText="Thanks for signing up! We promise only the best updates and growth rocket fuel coming your way."
        />
      </Section>
      {openModal && (
        <VideoModal src={activeVideo} setActiveVideo={setActiveVideo} />
      )}
    </Layout>
  );
};

export default VideoCategory;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    firstlook: allYtFirstLook {
      nodes {
        snippet {
          description
          title
          thumbnails {
            high {
              url
            }
          }
        }
        id
      }
    }
    growth: allYtGrowth101 {
      nodes {
        snippet {
          description
          title
          thumbnails {
            high {
              url
            }
          }
        }
        id
      }
    }
    insights: allYtFeatured {
      nodes {
        snippet {
          description
          title
          thumbnails {
            high {
              url
            }
          }
        }
        id
      }
    }
    customerstories: allYtCustomerStories {
      nodes {
        snippet {
          description
          title
          thumbnails {
            high {
              url
            }
          }
        }
        id
      }
    }
  }
`;
