import { Link } from "gatsby";
import React from "react";
import Video from "../../../images/video.svg";
import Header, { HeaderVariant } from "../../Header";

interface VideoCardProps {
  publication: any;
  setActive: any;
  id?: string;
}

const VideoCard: React.FC<VideoCardProps> = props => {
  const { publication, setActive, id } = props;
  const { title, thumbnails = {}, publishedAt } = publication;

  const truncate = (str: string, n: number) => {
    return str?.length > n ? str?.substring(0, n - 1) + "..." : str;
  };

  return (
    <div
      onClick={() => {
        setActive(id);
      }}
      className="cursor-pointer flex h-full flex-col justify-start relative md:pb-2 group"
    >
      <div className="overflow-hidden relative rounded-xl">
        <div className="absolute z-20 rounded-xl opacity-0 w-full h-full bg-gradient-to-br from-blue-1 to-purple-1 transition duration-200 ease-in group-hover:opacity-50"></div>
        <img
          className="w-full scale-105 group-hover:scale-100 transition duration-200 ease-in"
          src={`https://img.youtube.com/vi/${id}/maxresdefault.jpg`}
          alt="preview"
        />
        <Video className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
      </div>
      <div className="flex flex-col flex-grow py-4">
        <Header
          className="flex flex-grow group-hover:text-indigo-1"
          variant={HeaderVariant.h3}
        >
          <span className="text-lg font-semibold">
            {truncate(publication?.title, 120)}
          </span>
        </Header>

        <p className="text-gray-2 text-base bottom-0 mt-6">{publishedAt}</p>
      </div>
    </div>
  );
};

export default VideoCard;
