import React, { useEffect, useState } from "react";
import VideoCard from "../VideoCard";
import Header, { HeaderVariant } from "../../Header";
import Chevron from "../../../images/chevron.svg";
import Play from "../../../images/play.svg";
import { nav } from "../../../utils/navigation";
import Loading from "../../Loading";
import VideoFeatureCard from "../VideoFeatureCard";
import cn from "classnames";

interface VideoListProps {
  title?: string;
  CTA?: any | null;
  ctaPosition?: any;
  color?: string;
  button?: string;
  ctaSize?: string;
  categoryPage?: boolean;
  videos?: any;
  setActiveVideo?: any;
  results?: boolean;
  pagination?: boolean;
}

const VideoList: React.FC<VideoListProps> = props => {
  const {
    title,
    CTA,
    ctaPosition,
    ctaSize,
    button = "bg-gray-4-dark",
    color = "text-gray-4-dark",
    categoryPage = false,
    videos,
    setActiveVideo,
    pagination = false
  } = props;

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSplitVideos, setPageSplitVideos] = useState([]);

  const ctaColumnSize = {
    small: "col-span-6 md:col-span-3 lg:col-span-2",
    medium: "col-span-6 md:col-span-3 lg:col-span-4",
    large: "col-span-6"
  };

  useEffect(() => {
    const splitVideos = [];
    const chunkSize = pagination ? 9 : 5;
    for (let i = 0; i < videos?.length; i += chunkSize) {
      const chunk = videos?.slice(i, i + chunkSize);
      splitVideos.push(chunk);
    }
    setPageSplitVideos(splitVideos);
  }, [videos?.length]);

  useEffect(() => {
    if (pagination) {
      let url = new URL(window?.location.href);
      url.search = "";
      let currentURLParams = new URLSearchParams(window?.location.search);
      if (currentURLParams.get("page")) {
        setPageNumber(parseInt(currentURLParams.get("page")) - 1);
      } else {
        setPageNumber(0);
        nav(`/video/${title.split(" ").join("-").toLocaleLowerCase()}/?page=1`);
      }
    }
  }, []);

  return (
    <>
      <div
        className={cn(
          "w-full flex flex-col md:flex-row justify-between items-center mb-8",
          categoryPage && "items-start"
        )}
      >
        <Header
          className={cn(
            "pt-3 lg:pt-0 w-auto flex flex-col md:flex-row justify-center items-center",
            categoryPage && "flex-row md:flex-row justify-start"
          )}
          variant={HeaderVariant.h2}
        >
          <Play aria-hidden={true} className={cn(color, "w-12 mr-6")} />
          <span
            className={cn(
              `${color} text-4xl lg:text-5xl font-bold flex`,
              !categoryPage && "text-center md:text-left"
            )}
          >
            {title}
          </span>
        </Header>
        {!categoryPage && (
          <button
            className={cn(
              button,
              "text-white px-6 py-3 text-2xl rounded-xl font-medium focus:outline-none flex items-center mb-8 group lg:mb-12"
            )}
            onClick={() => {
              nav(`/video/${title?.split(" ").join("-").toLowerCase()}/`);
            }}
          >
            All {title}{" "}
            <Chevron className="fill-white w-2 ml-4 transition transition-delay-300 ease-in -translate-x-1 group-hover:translate-x-1" />
          </button>
        )}
      </div>
      <ul className="grid grid-cols-8 lg:pb-6 relative">
        {pageSplitVideos?.length > 0 ? (
          pageSplitVideos[pageNumber].map(({ id, snippet }, i) => {
            if (snippet.title !== "Private video") {
              if (i < 1) {
                return (
                  <VideoFeatureCard
                    setActive={setActiveVideo}
                    publication={snippet}
                    id={id}
                  />
                );
              }
              if (CTA && i === ctaPosition) {
                return (
                  <div
                    key={id}
                    className={`sm:mx-4 ${ctaSize ? ctaColumnSize[ctaSize] : ctaColumnSize["small"]
                      }`}
                  >
                    <CTA />
                  </div>
                );
              } else {
                return (
                  <div
                    key={i}
                    className="col-span-8 sm:mx-4  md:col-span-4 lg:col-span-2"
                  >
                    <VideoCard
                      publication={snippet}
                      setActive={setActiveVideo}
                      id={id}
                    />
                  </div>
                );
              }
            }
          })
        ) : (
          <div className="absolute bottom-1/2 left-1/2 -translate-x-1/2 translate-y-52">
            <Loading />
          </div>
        )}
      </ul>
      {pagination && (
        <div className="border-t-2 border-indigo-1 pb-10">
          <div className="py-3 flex items-center gap-3">
            <p className="mr-3 text-md text-indigo-1 font-semibold">
              Page {pageNumber + 1 || 1} of {pageSplitVideos.length}
            </p>
            <div className="bg-indigo-1 h-7 w-[2px]"></div>
            {pageSplitVideos?.map((item, i) => {
              return (
                <button
                  onClick={() => {
                    setPageNumber(i);
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                    nav(
                      `/video/${title
                        .split(" ")
                        .join("-")
                        .toLocaleLowerCase()}/?page=${i + 1}`
                    );
                  }}
                  className={cn(
                    "ml-3 w-7 h-7 pt-[1px] rounded-full flex items-center justify-center focus:outline-none",
                    pageNumber === i
                      ? "bg-indigo-1 text-white"
                      : "text-indigo-1"
                  )}
                  key={item.id}
                >
                  {i + 1}
                </button>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default VideoList;
